@font-face {
  font-family: 'Noto Sans JP';
  src: url(../assets/fonts/NotoSansCJKjp-Regular.otf) format('truetype');
}

@font-face {
  font-family: 'Avenir';
  src: url(../assets/fonts/AvenirLTStd-Black.otf) format('truetype');
}

body {
  font-family: 'Noto Sans', 'Noto Sans JP', sans-serif;
}
