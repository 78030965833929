:root {
    --amplify-primary-color: #B76E71;
    --amplify-primary-tint: #B76E71;
    --amplify-primary-shade: #B76E71;
  }
  
  amplify-sign-in {
  }

  amplify-sign-out {
  }