.containerImage {
    overflow: hidden;
    .image {
      width: 100%;
      margin: auto;
      margin-top: -10px;
      // margin-bottom: -20px;
    }
  }
.coverDanger {
    text-align: center;
    margin-top: 50px;
    .image {
        resize: cover;
    }
    .text2 {
        margin: 50px;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 25px;
        // text-align: start;
        text-align: center;
        letter-spacing: -0.02em;
        color: #000000;
    }
    .text1 {
        @extend .text2;
        margin-top: 20px
    }
    .text4 {
        margin-right: 50px;
        margin-left: 50px;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        // line-height: 20px;
        /* or 143% */
        // text-align: center;
        color: #000000
    }
    .link {
        margin-right: 50px;
        margin-left: 50px;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        // line-height: 20px;
        /* or 143% */
        // text-align: center;
        color: #B76E71 
    }
    .text3 {
        @extend .text4;
        margin-top: 25px;
    }
    .text8 {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        /* or 143% */
        text-align: center;
        color: #000000;
        margin-top: 30px;
        margin-bottom: 20px;
    }
}

 :global {
    .ant-modal-close-x {
        color: #000000 !important;
    }
    .ant-modal-footer {
        display: none !important;
    }
}