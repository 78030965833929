.pageWrapper {
    display: flex;
}

.mainWrapper {
    height: 100%;
    flex: 1;
    width: 100%;
}

.pageContent {
    // background-image: url('../../assets//images//imgBackground.png');
    flex: 1;
    overflow: auto;
    // height: 100vh;
    // padding-top: 60px;
}

@media only screen and (max-width: 500px) {
    .pageContent {
        // padding-top: 16vw;
    }
}

.coverPage {
    padding-top: 0
}